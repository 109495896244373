label,
input {
  display: block;
}
input.text {
  margin-bottom: 12px;
  width: 95%;
  padding: 0.4em;
}
fieldset {
  padding: 0;
  border: 0;
  margin-top: 25px;
}
h1 {
  font-size: 1.2em;
  margin: 0.6em 0;
}
.ui-dialog .ui-state-error {
  padding: 0.3em;
}
.validateTips {
  border: 1px solid transparent;
  padding: 0.3em;
}
.dialog-form {
  min-height: 256px;
}
.ui-widget-overlay {
  background: #aaaaaa;
  opacity: 0.3;
}
