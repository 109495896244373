#root {
  height: 100%;
}
html,
body {
  height: 100%;
  width: 100%;
  background-color: #000;
  font-family: 'Open Sans', sans-serif;
}

* {
  margin: 0 auto;
  padding: 0px;
}
/*---Comman CSS---*/
.spacer {
  height: 5px;
}
a {
  color: inherit;
  text-decoration: none;
}
.container {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.text-white {
  color: #fff;
}
.text-center {
  text-align: center;
}
.normal {
  font-weight: 400;
}
.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}
.p-50 {
  padding: 50px;
}
.p-10 {
  padding: 10px;
}
.mt-30 {
  margin-top: 30px;
}
.d-inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
}
.pr-5 {
  padding-right: 20px;
}
.text-right {
  text-align: right;
}
.input-content option {
  color: #000;
}
/*---Login CSS---*/

.login-wrapper {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo,
.comments,
.load-table {
  margin-bottom: 50px;
}
.load-table {
  overflow-x: auto;
}

.comments {
  margin-top: 50px;
  font-size: 18px;
}
.custom-input-form-control {
  margin: 0px;
  width: 100%;
  display: flex;
  margin-bottom: 5px;
}

.login-section .custom-input {
  min-width: 300px;
  max-width: 400px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 20px 20px;
  font-size: 18px;
  line-height: 1.5;
  height: 36px;
  color: #fff;
  background-color: #262626;
  background-clip: padding-box;
  border: 0px none;
}

.login-section .custom-input::placeholder {
  color: #fff;
  opacity: 1;
}

.login-section .custom-input:-ms-input-placeholder {
  color: #fff;
}

.login-section .custom-input::-ms-input-placeholder {
  color: #fff;
}

.login-btn {
  border: 1px solid #fff;
  padding: 20px;
  width: 60%;
  background-color: transparent;
  float: left;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
}
.login-btn:hover {
  background-color: #fff;
  color: #000;
}

/*---Dashboard CSS---*/

.frams-header {
  justify-content: space-between;
  margin-top: 50px;
  align-items: center;
}
.frams-header h3 {
  color: #fff;
  margin: 0px 50px;
  flex: 1;
}

.tab-wrapper {
  margin-top: 100px;
}

.tab-wrapper .tab-btn {
  background-color: transparent;
  border: 1px solid #fff;
  padding: 15px 39px;
  color: #fff;
  font-size: 18px;
  margin: 0 10px 0 0;
  text-decoration: none;
  border-bottom: 0px;
}
.tab-wrapper .tab-btn.active {
  background-color: #fff;
  color: #000;
}

.tab-body {
  margin-bottom: 70px;
  border: 1px solid #fff;
  color: #fff;
}

.tab-body .create-scene .form-group h3,
.tab-body .create-scene .form-group input {
  margin: 0px;
}

.create-scene .form-group {
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.form-group .input-heading {
  width: 100%;
  font-weight: 400;
  flex: 1;
}
.form-group .input-heading-inner {
  width: 14%;
  font-weight: 400;
}
.form-group .input-content {
  /* width: 100%; */
  width: 34%;
  margin: 0px;
}
.form-group .form-control {
  background-color: transparent;
  box-shadow: none;
  border: 0px;
  border: 1px solid #fff;
  padding: 15px;
  width: 100%;
}

.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url('../img/down-arrow.png') 98%/30px no-repeat #fff;
  color: #fff;
  font-size: 18px;
}

.input-creat-scene {
  /* width: 100%; */
  width: 34%;
  max-width: 700px;
  height: 32px;
  background-color: #262626;
  border: 0px;
  box-shadow: none;
  padding: 20px 20px;
  font-size: 18px;
  color: #fff;
  flex: auto;
}

.start-btn {
  background-color: #fff;
  /* padding: 24px; */
  padding: 14px;
  width: auto;
  float: left;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
  display: flex;
  margin-left: 10px;
  justify-content: center;
  flex: auto;
}

.status-completed {
  background-color: #76b82a;
  padding: 10px;
  margin-top: 10px;
}

.footer-content {
  padding: 15px 10px;
}

.load-scene-heading {
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.load-scene-heading .file {
  justify-content: center;
  align-items: center;
}
.load-scene-heading .file img {
  margin-left: 10px;
  margin-right: 10px;
}

.load-table tr th {
  text-align: left;
  background-color: #fff;
  padding: 15px 25px;
  color: #000;
}

.load-table tr td {
  padding: 5px 25px;
  background-color: #262626;
  white-space: nowrap;
}

.content-middle {
  display: flex;
  align-items: center;
}

.content-middle img {
  margin: 10px;
}
.footer-sec {
  margin: 15px 0px;
}
.messages .error {
  color: red;
  font-weight: bold;
  padding-bottom: 10px;
}
td.empty {
  color: #777;
  font-style: italic;
  text-align: center;
}
button {
  cursor: pointer;
}
.footer-text {
  color: #fff;
  text-align: center;
  font-weight: bold;
  bottom: 15px;
  position: fixed;
  width: 100%;
}
.create-scene-msg {
  float: right;
  color: green;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.dialog {
  width: 90vw;
  max-width: 24rem;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  background-color: white;
  z-index: 100;
}

.dialog > div + div {
  margin-top: 1rem;
}

.dialog .dialog-actions {
  text-align: right;
}

.dialog .dialog-actions button + button {
  margin-left: 1rem;
}

.dialog-open body::after {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.dialog-open body > *:not(.dialog) {
  filter: blur(0.25rem);
}
.containlog {
  width: 49%;
  float: left;
  padding: 5px;
}
.action-ok,
.action-cancel {
  display: inline-block;
  background: #454545;
  background: linear-gradient(to bottom, #454545 0%, #000000 100%);
  width: auto;
  padding: 0.125rem 3rem;
  line-height: 2rem;
  border: 0;
  border-radius: 1.125rem;
  color: white;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

#file_drop_target {
  position: relative;
  padding: 2rem 6rem;
  height: 120px;
}
#file_drop_target span {
  position: absolute;
  left: 50%;
}
#file_drop_target input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid;
}

#file_drop_target.drag_over {
  border-color: #96c4ea;
  color: #96c4ea;
}

.no_write #mkdir,
.no_write #file_drop_target {
  display: none;
}

.progress_track {
  display: inline-block;
  width: 100%;
  max-width: 12rem;
  height: 0.75rem;
  border: 1px solid #333;
  margin: 0 0.25rem 0 0.75rem;
}

.progress {
  background-color: #82cffa;
  height: 0.75rem;
}

#upload_progress {
  padding: 0.5rem;
}

#upload_progress > div {
  margin: 0.5rem;
}

.ui-dialog {
  position: absolute !important;
  height: auto !important;
  width: 350px !important;
  top: 112.5px !important;
  left: 504px !important;
  z-index: 101 !important;
}

.ui-dialog,
.ui-button-icon {
  background-color: #ffffff !important;
}

.ui-dialog-titlebar,
.ui-button {
  background-color: #000000 !important;
  color: #ffffff !important;
  font-weight: bold !important;
}
.save-check {
  margin: 0 20px;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: wait;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0, rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0, rgba(255, 255, 255, 0.75) 0 1.5em 0 0, rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0, rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0, rgba(255, 255, 255, 0.75) 0 -1.5em 0 0, rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
