.custom-disabled {
  cursor: not-allowed;
  color: gray;
}
.upload_progress_div .percentage {
  color: #04ff00;
}
.link-action {
  cursor: pointer;
}
.uploadStatus {
  font-size: 13.5px;
  color: #dedede;
}
.td-filename {
  max-width: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-error {
  color: #ff4e4e;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-underline {
  text-decoration: underline;
}
#instant-scene {
  margin-top: 5px;
}
.td-filename,
.td-scene-filename {
  max-width: 100px;
}
.td-filename span,
.td-scene-filename span {
  white-space: pre-wrap;
  word-wrap: break-word;
}

#root > .frams-header.dialog-open,
.dialog-open > div:not(.dialog) {
  filter: blur(0.25rem);
}

.download-btn {
  border: 1px solid #fff;
  padding: 20px;
  width: 100%;
  background-color: transparent;
  float: left;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 18px;
}
.download-btn:hover {
  background-color: #fff;
  color: #000;
}
.frames-for-mac,
.frames-for-windows {
  padding-bottom: 10px;
}

.input-frames {
  width: 100%;
  margin-top: 10px;
  max-width: 800px;
  /* max-width: 645px; */
  height: 32px;
  background-color: #262626;
  border: 0px;
  box-shadow: none;
  padding: 20px 20px;
  font-size: 18px;
  color: #fff;
  flex: auto;
}
.input-frames-progress {
  width: 100%;
  margin-top: 10px;
  /* max-width: 800px; */
  /* max-width: 645px; */
  height: 32px;
  background-color: #262626;
  border: 0px;
  box-shadow: none;
  padding: 20px 20px;
  font-size: 15px;
  color: #fff;
  flex: auto;
}
#frames-version-form input {
  width: 600px;
  margin-top: 10px;
}
#frames-upload-form input {
  width: 100%;
  max-width: 100%;
  margin-top: 10px;
}
.frames-upload-cancel {
  float: right;
  padding: 5px;
  margin: 0px;
}

.error-message {
  color: #d50e0e;
}
.frames-upload-submit {
  width: 200px;
  padding-top: 20px;
  display: flex;
}

.create-scene-msg.float-unset {
  float: unset;
}
